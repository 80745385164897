import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
//import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
//import { ControlGroup, InputGroup, Button, FileInput } from "@blueprintjs/core";
import { ControlGroup, InputGroup, Button } from "@blueprintjs/core";
import BookItem from '../component/BookItem';
import UploadButton from '../component/UploadButton';



import DocumentTitle from 'react-document-title';

function readTextFile(file, callback) {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function () {
        //console.log(rawFile.readyState +":"+rawFile.status+":"+(rawFile.status === 200));
        if (rawFile.readyState === 4 && rawFile.status === 200) {
            callback(rawFile.responseText);
        }
    }
    rawFile.send(null);
}
function isRemote(url){
    let remote=false;
    if (url.substring(0, 4) === "http" || url.substring(0, 4) === "ftp") {
        remote=true
    }
    return remote;
}
@withRouter
@translate()
@inject("store")
@observer
export default class List extends Component {
    state = {
        "books": [],
        "bookurl": ""
    }
    doJump() {
        let encodeBookUrl = this.state.bookurl;
        if (isRemote(this.state.bookurl)) {
            encodeBookUrl = encodeURIComponent(encodeURIComponent(this.state.bookurl));
        }
        if (this.state.bookurl.indexOf(".json") !== -1 || this.state.bookurl.indexOf(".h2") === -1) {
            this.props.history.push('/list/' + encodeBookUrl);
        } else {
            this.props.history.push('/read/' + encodeBookUrl);
        }
        window.location.reload();
        //console.log( this.props );
    }

    componentDidMount() {
        let bookpath = `/books/index.json`;
        const param = this.props.match.params.id;


        if (param) {
            if(isRemote(param)){
                bookpath = decodeURIComponent(param);
            }else {
                bookpath = `/books/${param}`;
            }
            if (param.substring(param.length - 5) !== ".json") {
                bookpath=bookpath + (bookpath.charAt(bookpath.length-1)==="/"?"index.json":"/index.json")
            }
        }

        try {
            readTextFile(bookpath, (data) => {
                if (data) {
                    let jsonData = { ...JSON.parse(data) }
                    for (const book of jsonData.books) {
                        //相对路径不带后缀
                        book.bookurl = (book.bookurl.substring(0,1)!=="/"?bookpath.substring(0, bookpath.lastIndexOf("/") + 1):"") + book.bookurl + (this.state.bookurl.indexOf(".h2book") === -1?".h2book":"")
                        book.bookurl = encodeURIComponent(encodeURIComponent(book.bookurl))
                    }
                    this.setState({ ...jsonData })
                }
            });
        } catch (e) {
            alert("列表文件载入失败，载入默认列表。");
            console.log(e);
            this.props.history.replace("/");
        }

    }

    render() {
        const books = this.state.books;
        let count = 1;

        const main = <div className="list-page">
            <div className="jumpbox">
                <div className="row">
                    <div className="left">
                        <ControlGroup fill={true} vertical={false}>

                            <InputGroup placeholder="输入.h2book文件的url..." value={this.state.bookurl} onChange={(evt) => { this.setState({ "bookurl": evt.target.value }) }} large={true} />

                            <Button icon="arrow-right" onClick={() => { this.doJump() }} large={true} />

                        </ControlGroup>
                    </div>
                    {this.state.upload_url && <div className="right">
                        <UploadButton upload_url={this.state.upload_url} site_url={this.state.site_url} />

                    </div>}
                </div>



            </div>

            <div className="logoline">
                <img src="/image/h2.logo.png" alt="logo" />
                {/* <div className="subtitle">列表</div> */}
            </div>
            <ul className="booklist">
                {books.length > 0 && books.map((item) => {
                    // console.log( item );
                    return <BookItem key={count++} data={item} />
                })}
            </ul>

        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}